import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LanguageModel } from "../models/interfaces/Language.model";
import { BaseService } from "./base.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IRequestOptions } from "./utils.service";
import { AccountUserModel, AuthenticationModel } from "../models/interfaces/authentication.model";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, map, Observable } from "rxjs";
import {StorageKey} from "../../environments/environment.storage";
export interface Dictionary<T> { [key: string]: T }

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService extends BaseService {

  router = inject(Router);
  cookieService = inject(CookieService);
  private Preferences: any;
  languageModel!: LanguageModel[];
  static lang: string = '';
  private static labels: Dictionary<string>;
  private isUserLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUserLogged$: Observable<boolean> = this.isUserLogged.asObservable();
  private registeredPassword: BehaviorSubject<string> = new BehaviorSubject<string>('');
  registeredPassword$: Observable<string> = this.registeredPassword.asObservable();


  constructor(private http: HttpClient) {
    super();
  }


  /** Store the user data in cookies **/
  storeUserData(user: AccountUserModel) {
    this.cookieService.set('quovo_token', user.quovo_token);
  }

  /**
   * Get JWT
   * */
  public get token(): string {
    return this.cookieService.get('quovo_token');
  }

  /**
   * Is user logged
   * */
  public get isAuthenticated() {
    return this.token;
  }


  /** Remove the user data from cookies **/
  restoreUserData() {
    localStorage.removeItem(StorageKey.isLogged);
    this.cookieService.delete('quovo_token');
    this.cookieService.delete(StorageKey.lang);
    this.isUserLogged.next(false);
    this.router.navigate(['/login']);
  }


  setLang(lang: string) {
    AuthenticationService.lang = lang;
    this.cookieService.set(StorageKey.lang, lang);
    //return this.getLangs()
  }

  getLang() {
    if (AuthenticationService.lang == 'it') {
      return 'it'
    } else {
      return 'en'
    }
    return localStorage.getItem(StorageKey.lang);
  }


  configAuthentication(bodyLogin: AuthenticationModel): Observable<AccountUserModel> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_AUTHENTICATION_CONFIG);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.isUserLogged.next(true);
    let options: IRequestOptions = { headers: header, withCredentials: true } as IRequestOptions;
    return this.http.post<any>(endpoint, bodyLogin, options).pipe(
      map((response: any) => {
        console.log(response);
        //this.token;
        //this.storeUserData(user);
        //const cookieValue = this.cookieService.getAll();
        //console.log(cookieValue);
        return response;
      }));
  }


  removeAuthentication(): Observable<any> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_LOGOUT);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options: IRequestOptions = { headers: header, withCredentials: true } as IRequestOptions;
    return this.http.get<any>(endpoint, options);
  }


  registerAccount(account: AuthenticationModel): Observable<AuthenticationModel> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_REGISTER_ACCOUNT);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.registeredPassword.next(account.currentPassword);
    let options: IRequestOptions = { headers: header, withCredentials: true } as IRequestOptions;
    return this.http.post<AuthenticationModel>(endpoint, account, options);
  }

  confirmAccount(codeKey: number, emailaddress: string): Observable<AccountUserModel> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_REGISTER_CONFIRMATION);
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('codeKey', codeKey);
    queryParams = queryParams.append('emailaddress', emailaddress);
    let options = { params: queryParams, withCredentials: true } as IRequestOptions;
    return this.http.get<AccountUserModel>(endpoint, options).pipe(map(user => {
      this.token;
      this.storeUserData(user);
      return user;
    }));;
  }


  changePasswordAccount(account: AuthenticationModel): Observable<AuthenticationModel> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_CHANGE_PASSWORD);
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options: IRequestOptions = { headers: header, withCredentials: true } as IRequestOptions;
    return this.http.post<AuthenticationModel>(endpoint, account, options);
  }


  resetPasswordEmail(email: string): Observable<any> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_RESET_PASSWORD_FOR_EMAIL);
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('email', email);
    let options = { params: queryParams, withCredentials: true } as IRequestOptions;
    return this.http.get<any>(endpoint, options);
  }

  googleAuthenticate(tokenID: string): Observable<any> {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_GOOGLE_AUTH0_AUTHENTICATION);
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('tokenID', tokenID);
    this.isUserLogged.next(true);
    localStorage.setItem('tokenGoogleID', JSON.stringify(tokenID));
    let options = { params: queryParams, responseType: 'text', withCredentials: true } as IRequestOptions;
    return this.http.get<any>(endpoint, options);
  }

  getLangs(): void {
    const endpoint: string = this.getEndpoint(BaseService.ENDPOINT_NAME_GET_LANGUAGES);
    let options = { withCredentials: true } as IRequestOptions;
    this.http.get<LanguageModel[]>(endpoint, options).subscribe((data: LanguageModel[]) => {
      console.log(data);
    });

  }

}
